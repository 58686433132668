<script>
import { required, minLength } from 'vuelidate/lib/validators';
import TiptapEditor from '@shared/components/TiptapEditor.vue';
import { editor } from '@app/mixins/trainings';
import EditorLayout from '@app/layouts/EditorLayout.vue';
import TrainingItemResourcesEditor from '@app/components/TrainingItemResourcesEditor.vue';
import TrainingItemEditorStatusField from '@app/components/TrainingItemEditorStatusField.vue';

export default {
  mixins: [editor],
  components: {
    EditorLayout,
    TiptapEditor,
    TrainingItemResourcesEditor,
    TrainingItemEditorStatusField,
  },
  i18n: {
    messages: {
      fr: {
        statusOption: {
          draft: 'Mettre la liste en brouillon',
          published: 'Publier la liste',
        },
      },
    },
  },
  form: {
    description: {
      label: 'Entrez la description de cette liste',
      type: 'editor',
      column: 'is-12 pt-0',
      inputAttrs: {
        maxlength: 512,
      },
    },
  },
  data() {
    return {
      trainingItem: {
        id: null,
        parent_id: null,
        type: 'TASKS',
        name: '',
        draft: false,
        description: '',
        resources: [],
        min_complete_duration: 0,
        specific_content: {
          tasks: [],
        },
      },
      tasks: [],
      showMinCompleteDuration: false,
    };
  },
  watch: {
    tasks: {
      deep: true,
      handler() {
        this.isDirty = true;
      },
    },
  },
  methods: {
    setTrainingItem(data) {
      Object.assign(this, {
        trainingItem: data,
        tasks: data.specific_content.tasks,
      });

      this.showMinCompleteDuration = this.trainingItem.min_complete_duration > 0;

      this.$nextTick(() => (this.isDirty = false));

      return data;
    },
    updateTrainingItem(trainingItem, silent = false) {
      this.isLoading = true;

      const { uuid } = this.currentTraining;
      const trainingItemId = this.trainingItem.id;

      return this.$store.dispatch('trainings/updateItem', {
        uuid,
        trainingItemId,
        trainingItem,
      })
        .then(() => (!silent && this.$showMessage.success()))
        .finally(() => (this.isLoading = false));
    },
    scrollToTask(ref) {
      this.$scrollTo(ref, {
        container: this.$refs.layout.$refs.body,
      });
    },
    handleNoTasksError() {
      if (!this.trainingItem.draft && !this.$v.tasks.required) {
        this.$buefy.dialog.alert({
          type: 'is-danger',
          message: `
            Vous ne pouvez pas enregistrer votre liste de tâches sans
            y ajouter au moins une tâche (sauf si vous mettez la liste en brouillon).
          `,
        });
      }
    },
    saveIfValid(isLoading) {
      const isValid = this.dataIsValid(isLoading);

      if (!isValid) {
        this.handleNoTasksError();
        this.$nextTick(() => this.$refs.layout.scrollInContainer('.has-error'));
        return;
      }

      this.save();
    },
    save(silent = false) {
      if (!silent) {
        this.setAutoUpdate(this.trainingItem.id);
      }

      const trainingItem = { ...this.trainingItem };
      trainingItem.content = JSON.stringify(trainingItem.content || []);
      const { uuid } = this.currentTraining;
      const tasks = this.tasks.map((task) => {
        task = { ...task };
        delete task.isOpen;
        return task;
      });

      trainingItem.specific_content.tasks = tasks;

      if (!trainingItem.id) {
        this.isLoading = true;

        this.$store
          .dispatch('trainings/addItem', { uuid, trainingItem })
          .then((data) => this.setTrainingItem(data))
          .then(({ id }) => this.$router.push({
            name: 'training_uuid_tasks',
            params: { uuid, id },
            query: { ...this.$route.query, parentId: trainingItem.parent_id },
          }))
          .then(() => this.$showMessage.success())
          .finally(() => (this.isLoading = false));

        return;
      }

      this.updateTrainingItem(trainingItem, silent);
    },
    generateId() {
      return Date.now() + Math.random().toFixed(8).substring(2);
    },
    addTask() {
      this.tasks.push({
        id: this.generateId(),
        isOpen: true,
        name: '',
        description: '',
      });
    },
    removeTask(task) {
      this.tasks = this.tasks.filter((v) => v !== task);
    },
  },
  validations() {
    let tasks = {};

    if (!this.trainingItem.draft) {
      tasks = { required };
    }

    return {
      trainingItem: {
        name: { required },
        specific_content: {
        },
      },
      tasks: {
        ...tasks,
        minLength: minLength(1),
        $each: {
          name: { required },
        },
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    this.handleBeforeRouteLeave(to, from, next);
  },
  created() {
    const promise = this.handleInitialLoading();

    if (promise) {
      promise.then(this.setTrainingItem);
    }
  },
};
</script>

<template>
  <EditorLayout ref="layout" class="tcomp" :trainingItem="trainingItem">
    <template #sidenav>
      <b-skeleton v-if="initialLoading" height="300" />
      <b-tabs v-else v-model="activeTab" size="is-small" :animated="false" expanded>
        <b-tab-item label="Tâches">
          <div class="columns is-multiline">
            <div class="column is-12">
              <b-field
                label="Entrez le nom de cette liste de tâches"
                v-bind="$getErrorProps($v.trainingItem.name, ['required'])">
                <b-input
                  v-model="trainingItem.name"
                  :has-counter="false"
                  maxlength="128"
                  autofocus
                  @keyup.native.enter="isDirty && saveIfValid(isLoading)"
                />
              </b-field>
            </div>
            <TrainingItemEditorStatusField
              class="column is-12 pt-0"
              v-model="trainingItem.draft"
            >
              <template #draft>
                <span v-t="'statusOption.draft'" />
              </template>
              <template #published>
                <span v-t="'statusOption.published'" />
              </template>
            </TrainingItemEditorStatusField>
            <div class="column" :class="$options.form.description.column">
              <BaseField v-model="trainingItem.description" :field="$options.form.description" />
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Liste">
          <p class="mb-3 has-text-centered">
            Vous avez la possibilité de réordonner les tâches ici.
          </p>
          <draggable v-if="tasks.length" v-model="tasks">
            <a
              v-for="(task, taskK) in tasks"
              :key="task.id"
              class="mb-2 p-2 is-block bradius-4 border-dashed color-inherit break-words"
              @click.prevent="scrollToTask(taskK)"
            >
              <b-icon icon="grip-lines" />
              {{ task.name }}
            </a>
          </draggable>
          <b-button class="mt-5" icon-left="plus" expanded @click="addTask">
            Ajouter une tâche
          </b-button>
        </b-tab-item>
        <b-tab-item label="Annexes">
          <TrainingItemResourcesEditor
            :training="currentTraining"
            :training-item="trainingItem"
          />
        </b-tab-item>
      </b-tabs>
    </template>
    <template #header_right>
      <b-button
        v-if="pageURL"
        type="is-text"
        size="is-small"
        icon-left="eye"
        @click="onViewDraft">
        Voir la page
      </b-button>
      <b-button
        type="is-primary"
        size="is-small"
        :loading="isLoading"
        :disabled="!isDirty"
        @click="saveIfValid(isLoading)"
      >
        Sauvegarder
      </b-button>
    </template>
    <template #footer>
      <b-button
        type="is-primary"
        size="is-small"
        :loading="isLoading"
        :disabled="!isDirty"
        @click="saveIfValid(isLoading)"
      >
        Sauvegarder les modifications
      </b-button>
    </template>
    <template #main>
      <b-skeleton v-if="initialLoading" height="500" />
      <template v-else>
        <h1 class="title is-2 has-text-centered">
          {{ trainingItem.name }}
        </h1>
        <div
          v-if="trainingItem.description"
          class="mw-768 mx-auto content"
          v-html="trainingItem.description"
        />

        <form class="mw-768 mx-auto" @submit.prevent="saveIfValid(isLoading)">
          <RenderlessToggle
            v-for="(task, taskK) in tasks"
            :key="task.id"
            #default="{ isOpen, toggle }"
            :open="!!task.isOpen"
          >
            <fieldset
              :ref="`task${taskK}`"
              class="tcomp_fieldset message mb-5 p-2"
              :class="{
                [`is-danger has-error`]: $v.tasks.$each[taskK].$error,
                'is-primary': !$v.tasks.$each[taskK].$error
              }">
              <div class="message-header">
                <a
                  class="w-full cursor-pointer tdecoration-none"
                  title="Cliquez pour cacher / afficher"
                  @click.prevent="toggle"
                >
                  <b-icon :icon="isOpen ? 'chevron-up' : 'chevron-down'" />
                  Tâche #{{ taskK + 1 }}
                  <template v-if="task.name">
                    (<span class="mw-200 w-auto has-text-clipped is-inline-block valign-sub">
                      {{ task.name }}
                    </span>)
                  </template>
                </a>
                <button
                  type="button"
                  class="delete"
                  aria-label="delete"
                  @click="removeTask(task)"
                />
              </div>
              <div v-show="isOpen" class="message-body has-background-white  has-text-black">
                <b-field
                  label="Entrez une tâche à effectuer"
                  :label-for="`task${taskK}`"
                  v-bind="$getErrorProps($v.tasks.$each[taskK].name, ['required'])"
                >
                  <b-input
                    v-model="task.name"
                    :id="`task${taskK}`"
                    maxlength="255"
                    expanded
                    @keydown.native.enter.stop.prevent
                    @blur="$v.tasks.$each[taskK].name.$touch()"
                  />
                </b-field>
                <b-field
                  label="Décrivez la tâche (facultatif)"
                  :label-for="`task${taskK}`"
                >
                  <TiptapEditor
                    :id="`task${taskK}`"
                    :value="task.description"
                    @input="task.description = $event"
                  />
                </b-field>
              </div>
            </fieldset>
          </RenderlessToggle>
          <p class="buttons is-centered">
            <b-button
              icon-left="plus"
              @click="addTask">
              Ajouter une tâche
            </b-button>
          </p>
        </form>
      </template>
    </template>
  </EditorLayout>
</template>

<style lang="scss" scoped>
.tcomp {
  .container {
    max-width: 728px;
  }

  &_fieldset {
    border: 2px dashed $theme_color_primary;
  }
}
</style>
